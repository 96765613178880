import Tittle from "@common/tittle/Tittle";
import { HowWeStartedData } from "@utils/DummyArray";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";

const HowWeStarted = () => {
  return (
    <div className="2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 py-10 sm:py-16 flex items-center w-full justify-center flex-col">
      <Tittle colorTitle="OUR JOURNEY" title="How We Started?" />
      <div className="flex sm:flex-row flex-col py-8 items-center justify-center ">
        {HowWeStartedData.map((data, index) => {
          const isIndex = index === 0 || index === 2;
          const isColor =
            index === 0 || index === 2 ? "bg-[#0D242E]" : "bg-[#63BE60]";
          const isColumn =
            index === 0 || index === 2 ? "flex-col" : "flex-col-reverse";
          return (
            <div
              className={`flex items-center justify-center flex-col xl:w-[322px] lg:w-52 md:w-[150px] w-full ${
                index === 0 || index === 2
                  ? "sm:flex-col"
                  : "sm:flex-col-reverse"
              } `}
            >
              <div
                className={`flex items-center justify-center flex-col h-44 ${
                  index === 0 || index === 2
                    ? "sm:flex-col"
                    : "sm:flex-col-reverse"
                }`}
                style={{
                  marginTop: index === 0 || index === 2 ? "20px" : "0px",
                }}
              >
                {/* Year */}
                <div
                  className={`flex justify-center items-center h-[68px] w-[68px] m-2 rounded-full ${
                    index === 0 || index === 2
                      ? "bg-[#0D242E] "
                      : "bg-[#63BE60]"
                  }  `}
                >
                  <span
                    className="text-base font-semibold"
                    style={{
                      color: ConstantFontColor.white,
                    }}
                  >
                    {" "}
                    {data?.year}
                  </span>
                </div>
                {/* Dot */}
                <div className={`flex rounded-full h-2 w-2 ${isColor}`}></div>
                {/* dashed Border */}
                <div
                  className={`flex w-1 h-20 border-l-2 border-dashed ${
                    isIndex ? "border-[#0D242E]" : "border-[#63BE60]"
                  }`}
                ></div>
                {/* Angled Timeline Segment */}
                <div
                  className={`xl:w-80 lg:w-48 md:w-[150px] sm:w-32 w-full h-4 mx-auto ${data.color}`}
                  style={{
                    clipPath: "polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%)",
                  }}
                ></div>
              </div>

              <div
                className={`lg:text-base sm:text-sm justify-center items-center flex lg:w-[70%] md:w-[80%] font-normal text-center ${
                  isIndex
                    ? "sm:pt-14 pt-3 sm:h-[56px]"
                    : "pb-2 sm:h-[279px] sm:pt-0 pt-3"
                }`}
                style={{
                  fontFamily: constantFontFamily.rubik,
                  color: ConstantFontColor.greyText,
                }}
              >
                {data?.description}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowWeStarted;
