import { MdOutlineEmail, MdPhoneInTalk } from "react-icons/md";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { CiLocationOn } from "react-icons/ci";
import { TbClockHour3 } from "react-icons/tb";
import {
  FaInstagram,
  FaLinkedinIn,
  FaRegThumbsUp,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { LiaFacebookF, LiaIndustrySolid } from "react-icons/lia";
import { imageData } from "@constant/images/Images";
import {
  HiOutlineCurrencyDollar,
  HiOutlineLightBulb,
  HiOutlineUserGroup,
} from "react-icons/hi2";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { PiSolarPanel } from "react-icons/pi";

export const contactDetailsFooter = [
  {
    type: "email",
    icon: (
      <MdOutlineEmail
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "info@hmcompany.in",
  },
  {
    type: "phone",
    icon: (
      <MdPhoneInTalk
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "+91 97147 38409",
  },
  {
    icon: (
      <CiLocationOn
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "4793 McLaughlin Loop, East Royce",
  },
  {
    icon: (
      <TbClockHour3
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "  Mon - Sat 8:00 - 17:30, Sunday - CLOSED",
  },
];

export const contactDetailsTopBar = [
  {
    type: "email",
    icon: (
      <MdOutlineEmail
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "info@hmcompany.in",
  },
  {
    type: "phone",
    icon: (
      <MdPhoneInTalk
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "+91 97147 38409",
  },
  {
    icon: (
      <TbClockHour3
        className="text-base"
        style={{ color: ConstantFontColor.white }}
      />
    ),
    name: "Mon - Sat 8:00 - 17:30, Sunday - CLOSED",
  },
];

export const contactDetailsGetInTouch = [
  {
    icon: (
      <MdOutlineEmail
        className="text-base"
        style={{ color: ConstantFontColor.lightGreyText }}
      />
    ),
    name: "info@hmcompany.in",
  },
  {
    icon: (
      <MdPhoneInTalk
        className="text-base"
        style={{ color: ConstantFontColor.lightGreyText }}
      />
    ),
    name: "+91 97147 38409",
  },
  {
    icon: (
      <CiLocationOn
        className="text-base"
        style={{ color: ConstantFontColor.lightGreyText }}
      />
    ),
    name: "4793 McLaughlin Loop, East Royce",
  },
];

export const socialMediaIcons = [
  { icons: <FaLinkedinIn /> },
  { icons: <LiaFacebookF /> },
  { icons: <FaTwitter /> },
  { icons: <FaYoutube /> },
  { icons: <FaInstagram /> },
];

export const testimonials = [
  {
    name: "Martha G. Evans",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    name: "Martha G. Evans 2",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    name: "Martha G. Evans 3",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
];

export const partnersData = [
  { image: imageData.LogoUGVCL },
  { image: imageData.LogoGUVNL },
  { image: imageData.LogoPGVCL },
  { image: imageData.LogoDGVCL },
  { image: imageData.LogoMGVCL },
  { image: imageData.MNRE },
  { image: imageData.GEDA },
];

export const footerTab = [
  { title: "Home" },
  { title: "About" },
  { title: "Services" },
  { title: "Gallery" },
  { title: "Contact" },
];

export const headerTab = [
  { title: "HOME" },
  { title: "ABOUT" },
  // { title: "SERVICES" },
  { title: "PROJECTS" },
  { title: "CONTACT" },
];

export const HowWeStartedData = [
  {
    year: "2013",
    description: "H&M Company incorporated",
    color: "bg-[#0D242E]",
    lineColor: "border-[#0D242E]",
  },
  {
    year: "2016",
    description:
      "300+ Satisfied customers Services added Solar Rooftop Solar pump Solar water heater",
    color: "bg-[#63BE60]",
    lineColor: "border-[#63BE60]",
  },
  {
    year: "2017",
    description:
      "700+ Satisfied customers H&M Company merged with RC Electricals",
    color: "bg-[#0D242E]",
    lineColor: "border-[#0D242E]",
  },
  {
    year: "2024",
    description:
      "1000+ Satisfied customers Services added Street lighting and Services",
    color: "bg-[#63BE60]",
    lineColor: "border-[#63BE60]",
  },
];

export const IntroduceData = [
  {
    title: "Misson",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
  {
    title: "Vision",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
];

export const questions = [
  { que: "Where can I watch?" },
  { que: "Tempus magna risus interdum ultricies sed urna?" },
  { que: "Augue in nibh urna volutpat mattis?" },
  { que: "Eu egestas sed sed posuere ultrices ?" },
  { que: " Elementum facilisi aliquam, nisi, orci vulputate?" },
];

export const ServicesData = [
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.waterPump,
    title: "Solar water pump",
    desc: "A solar pump is a system that uses solar energy to raise liquid from the ground to a higher level...",
  },
  {
    img: imageData.heaterSystem,
    title: "Solar water heater system",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.heaterSystem2,
    title: "Solar water heater system",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
  {
    img: imageData.roofTopSystem,
    title: "solar Rooftop Systems",
    desc: "H&M Company are also offering turnkey based solution in the area of grid connected solar rooftop system included...",
  },
];

export const chooseData = [
  {
    text: "Best energy solution",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
  {
    text: "24/7 Technical Support",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
  },
];

export const solutionData = [
  {
    icon: <HiOutlineCurrencyDollar className="sm:text-3xl text-xl" />,
    name: "Cost-Effective Solutions",
  },
  {
    icon: <AiOutlineDeploymentUnit className="sm:text-3xl text-xl" />,
    name: "Advanced Technology",
  },
  {
    icon: <HiOutlineLightBulb className="sm:text-3xl text-xl" />,
    name: "Customizable Solutions",
  },
];

export const experienceData = [
  {
    id: 1,
    icon: (
      <LiaIndustrySolid className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />
    ),
    number: " Years",
    description: "Industry Experience",
  },
  {
    id: 2,
    icon: (
      <HiOutlineUserGroup className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />
    ),
    number: " +",
    description: "Workforce Strength",
  },
  {
    id: 3,
    icon: <FaRegThumbsUp className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />,
    number: " +",
    description: "Happy Customer",
  },
  {
    id: 4,
    icon: <PiSolarPanel className="xl:h-10 xl:w-10 md:h-9 md:w-9 h-6 w-6" />,
    number: " +MW",
    description: "Solar project",
  },
];

export const currencies = [
  {
    value: "System installer",
    label: "System installer",
  },
  {
    value: "System installer 1",
    label: "System installer 1",
  },
  {
    value: "System installer 2",
    label: "System installer 2",
  },
  {
    value: "System installer 3",
    label: "System installer 3",
  },
];

export const ourProjectData = [
  {
    image: imageData.recentProject1,
    name: "solar Rooftop Systems",
    location: "4793 McLaughlin Loop, East Royce",
  },
  {
    image: imageData.recentProject2,
    name: "solar Rooftop Systems",
    location: "Hane Junction, West Emmyside, Michigan",
  },
  {
    image: imageData.recentProject3,
    name: "solar Rooftop Systems",
    location: "Zieme Ville, Eldridgeside, Washington",
  },
  {
    image: imageData.recentProject4,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
];
