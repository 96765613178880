import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaRegCircleDot } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { testimonials } from "@utils/DummyArray";
import Tittle from "@common/tittle/Tittle";

const ClientsSay = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => (prev + 1) % testimonials.length);
    }, 4000); // Auto-scroll every 4 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center px-4 py-12 text-center w-full">
      <Tittle
        colorTitle="CLIENT'S TESTIMONIALS"
        title=" Here's What Our Clients Say About Us"
        textAlign="center"
      />
      <div className="relative max-w-2xl w-full mt-6">
        <AnimatePresence mode="wait">
          <motion.div
            key={current}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="text-gray-600 text-sm sm:text-base leading-relaxed"
          >
            "{testimonials[current].text}"
          </motion.div>
        </AnimatePresence>
      </div>
      <p className="mt-4 font-semibold text-gray-900">
        {testimonials[current].name}
      </p>

      {/* Navigation Dots */}
      <div className="flex space-x-2 mt-4">
        {testimonials.map((_, index) => (
          <div style={{ color: ConstantFontColor.greyText }} key={index}>
            {index === current ? (
              <FaRegCircleDot className="text-base" />
            ) : (
              <GoDotFill />
            )}
          </div>
        ))}
      </div>

      {/* <div className="flex mt-6 space-x-4">
        <button
          onClick={() => setCurrent((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
          className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
        >
          <AiOutlineLeft />
        </button>
        <button
          onClick={() => setCurrent((prev) => (prev + 1) % testimonials.length)}
          className="p-2 bg-gray-200 hover:bg-gray-300 rounded-full"
        >
          <AiOutlineRight />
        </button>
      </div> */}
    </div>
  );
};

export default ClientsSay;
