import React from "react";
import Header from "@componets/header/Header";
import TopBar from "@componets/topBar/TopBar";
import HomeBanner from "./homeBanner/HomeBanner";
import HomeAboutsUs from "./homeAboutsUs/HomeAboutsUs";
import MainEnergyServices from "./energyServices/MainEnergyServices";
import ClientsSay from "@common/clientSay/ClientsSay";
import RecentProject from "@common/recentProject/RecentProject";
import OurPartner from "@common/ourPartner/OurPartner";
import Footer from "@componets/footer/Footer";
import Experience from "./experience/Experience";
import { ourProjectData } from "@componets/utils/DummyArray";

const Home = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"HOME"} />
      <HomeBanner />
      <div className="2xl:mt-[-100px] xl:-mt-16 lg:-mt-16 md:-mt-14 -mt-24">
        <Experience />
      </div>
      <HomeAboutsUs />
      <MainEnergyServices homePageCart />
      <RecentProject mainTitle showButton data={ourProjectData} limit={4} />
      <OurPartner />
      <ClientsSay />
      <Footer greenBanner />
    </div>
  );
};

export default Home;
