import React from "react";
import TopBar from "@componets/topBar/TopBar";
import Header from "@componets/header/Header";
import RecentProject from "@common/recentProject/RecentProject";
import { imageData } from "@constant/images/Images";
import TittleBanner from "@componets/mainTittleBanner/TittleBanner";
import ClientsSay from "@common/clientSay/ClientsSay";
import Footer from "@componets/footer/Footer";
import MainEnergyServices from "@home/energyServices/MainEnergyServices";

const projects = [
  {
    image: imageData.recentProject2,
    name: "solar Rooftop Systems",
    location: "4793 McLaughlin Loop, East Royce",
  },
  {
    image: imageData.project2,
    name: "solar Rooftop Systems",
    location: "Hane Junction, West Emmyside, Michigan",
  },
  {
    image: imageData.project3,
    name: "solar Rooftop Systems",
    location: "Zieme Ville, Eldridgeside, Washington",
  },
  {
    image: imageData.project4,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project5,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project6,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project7,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project8,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project9,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project10,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project11,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project12,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.recentProject1,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project13,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.project14,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
  {
    image: imageData.recentProject4,
    name: "solar Rooftop Systems",
    location: "Ciara Mills, Port Enola, West Virginia",
  },
];

const Project = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"PROJECTS"} />
      <TittleBanner mainTitle="Projects" subTitle="Projects" />
      <RecentProject data={projects} />
      <MainEnergyServices homePageCart />
      <ClientsSay />
      <Footer greenBanner />
    </div>
  );
};

export default Project;
