import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { contactDetailsTopBar, socialMediaIcons } from "@utils/DummyArray";

const TopBar = () => {
  return (
    <div
      className="md:flex hidden items-center py-4 justify-between 2xl:px-44 xl:px-28 lg:px-10 xl:flex-row md:flex-col"
      style={{ backgroundColor: ConstantFontColor.commanColor }}
    >
      <div className="flex">
        {contactDetailsTopBar.map((items) => {
          return (
            <div
              className="flex items-center 2xl:mr-40 xl:mr-20 lg:mr-10 md:mr-6"
              style={{ color: ConstantFontColor.white }}
            >
              <div className="text-base">{items?.icon} </div>
              <div
                className="pl-2 text-sm font-normal cursor-pointer"
                style={{ fontFamily: constantFontFamily.rubik }}
                onClick={() => {
                  if (items.type === "email") {
                    window.location.href = `mailto:${items.name}`;
                  } else if (items.type === "phone") {
                    window.location.href = `tel:${items.name}`;
                  }
                }}
              >
                {items?.name}{" "}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex xl:pt-0 md:pt-3">
        {socialMediaIcons.map((items) => {
          return (
            <div className="flex items-center">
              <div
                className="mx-5 text-sm"
                style={{ color: ConstantFontColor.white }}
              >
                {items?.icons}{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopBar;
