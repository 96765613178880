import React from "react";
import Tittle from "@common/tittle/Tittle";
import { imageData } from "@constant/images/Images";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { useInView } from "react-intersection-observer";
import { partnersData } from "@utils/DummyArray";

const OurPartner = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  return (
    <div className="flex flex-col">
      <div className="md:flex hidden w-full 2xl:h-[403px]">
        <img
          src={imageData.ourpartnerBg}
          className="md:h-80 lg:h-[360px] xl:h-[403px] 2xl:h-[427px] w-full"
          loading="lazy"
          alt="..."
        />
      </div>
      <div className="flex md:hidden w-full h-96">
        <img
          src={imageData.partnerBgMobile}
          loading="lazy"
          alt=""
          className="w-full h-full"
        />
      </div>
      <div className="2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 pt-14 flex absolute flex-col items-center justify-center w-full">
        <Tittle colorTitle="OUR PARTNER" />
        <h1
          className="xl:text-4xl sm:text-xl font-normal"
          style={{
            color: ConstantFontColor.white,
            fontFamily: constantFontFamily.rubik,
          }}
        >
          Gujrat Goverment
          <br /> Authorized Channel Partner
        </h1>
        <div
          ref={ref}
          className={`flex pt-5 flex-wrap justify-center ${
            partnersData.length === 4 ? "flex-col" : "flex-row"
          }`}
        >
          {partnersData.map((img, index) => {
            const delay = Math.floor(index) * 200;
            return (
              <div
                key={index}
                className={`flex sm:m-4 m-2 lg:h-20 lg:w-20  xl:h-28 xl:w-28 2xl:h-auto 2xl:w-auto h-12 w-12 
                  ${inView ? `animate-swing-in-top-fwd ` : "opacity-0"}`}
                style={{
                  animationDelay: `${delay}ms`,
                }}
              >
                <img
                  src={img.image}
                  loading="lazy"
                  alt=""
                  className="w-full h-full"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OurPartner;
