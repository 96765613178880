import Tittle from "@common/tittle/Tittle";
import EnergyServicesCart from "./energyServicesCart/EnergyServicesCart";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import ChooseUs from "./whyChooseUS/ChooseUs";
import RecentProject from "@common/recentProject/RecentProject";
import { ourProjectData } from "@utils/DummyArray";

interface MainEnergyServicesProps {
  homePageCart?: boolean;
}

const MainEnergyServices = ({ homePageCart }: MainEnergyServicesProps) => {
  return (
    <div>
      {homePageCart ? (
        <div
          className="flex justify-center items-center flex-col  py-14 md:bg-[linear-gradient(to_bottom,white_59%,#0D242E_59%)] sm:bg-[linear-gradient(to_bottom,white_47%,#0D242E_37%)] bg-[linear-gradient(to_bottom,white_50%,#0D242E_37%)]"
          style={{ backgroundColor: ConstantFontColor.lightBackgroundColor }}
        >
          <Tittle
            colorTitle="SPECIALISE IN THE ENERGY SERVICE"
            title="Sustainable Energy Services"
            textAlign="center"
          />
          <EnergyServicesCart />
        </div>
      ) : (
        <div
          className="flex justify-center items-center flex-col xl:bg-[linear-gradient(to_bottom,white_55%,#0D242E_55%)] lg:bg-[linear-gradient(to_bottom,white_37%,#0D242E_37%)] md:bg-[linear-gradient(to_bottom,white_36%,#0D242E_36%)] sm:bg-[linear-gradient(to_bottom,white_47%,#0D242E_37%)] bg-[linear-gradient(to_bottom,white_18%,#0D242E_18%)]"
          style={{ backgroundColor: ConstantFontColor.lightBackgroundColor }}
        >
          <RecentProject
            mainTitle
            showButton
            data={ourProjectData}
            limit={4}
            homePageCart={homePageCart ? "true" : "false"}
          />
        </div>
      )}

      <ChooseUs />
    </div>
  );
};

export default MainEnergyServices;
