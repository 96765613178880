import React from "react";
import TopBar from "@componets/topBar/TopBar";
import Header from "@componets/header/Header";
import TittleBanner from "@componets/mainTittleBanner/TittleBanner";
import GetInTouch from "./getInTouch/GetInTouch";
import OurPartner from "@common/ourPartner/OurPartner";
import Faq from "./faq/Faq";
import Footer from "@componets/footer/Footer";

const ContactUS = () => {
  return (
    <div>
      <TopBar />
      <Header selectedTabItem={"CONTACT"} />
      <TittleBanner mainTitle="Contact US" subTitle="Contact Us" />
      <GetInTouch />
      <OurPartner />
      <Faq />
      <Footer />
    </div>
  );
};

export default ContactUS;
