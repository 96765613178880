import { HashRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "@home/Home";
import AboutUs from "@pages/aboutUs/AboutUs";
import Services from "@pages/services/Services";
import Project from "@pages/project/Project";
import ContactUS from "@pages/contactUS/ContactUS";
import RequestQuote from "@pages/requestQuote/RequestQuote";
import { Suspense } from "react";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/projects" element={<Project />} />
            <Route path="/contactUS" element={<ContactUS />} />
            <Route path="/requestQuote" element={<RequestQuote />} />
          </Routes>
          <ToastContainer position="top-right" autoClose={3000} />
        </HashRouter>
      </Suspense>
    </div>
  );
}

export default App;
