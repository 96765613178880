import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { imageData } from "@constant/images/Images";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "@constant/ConstantFontColor";
interface TittleBannerProps {
  mainTitle: string;
  subTitle: string;
}

const TittleBanner = ({ mainTitle, subTitle }: TittleBannerProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex">
      <div className="h-36 sm:w-auto sm:h-auto xl:h-72  2xl:h-[384px] w-full relative">
        <img
          src={imageData.BannerTitle}
          loading="lazy"
          alt=""
          className="sm:w-full sm:h-auto h-36"
        />
      </div>
      <div className="flex absolute w-full h-36 md:h-[153px] lg:h-52 xl:h-72 2xl:h-[384px] justify-center items-center flex-col">
        <h1
          className="animate-zoomInDown transition-all duration-500 2xl:text-6xl xl:text-4xl md:text-2xl sm:text-xl text-xl font-medium text-center"
          style={{
            fontFamily: constantFontFamily.rubik,
            color: ConstantFontColor.white,
          }}
        >
          {mainTitle ?? ""}{" "}
        </h1>
        <div
          className="flex items-center text-base font-medium"
          style={{ fontFamily: constantFontFamily.rubik }}
        >
          <span
            className="flex items-center text-[#FFE400] cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            Home <RiArrowRightSLine className="mx-2" />
          </span>
          <span style={{ color: ConstantFontColor.white }}>
            {subTitle ?? ""}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TittleBanner;
