const imageData = {
  hmSolarLogo: require("@assets/images/hmSolarLogo.png"),
  banner: require("@assets/images/Banner.png"),
  BannerHome: require("@assets/images/BannerHome.png"),
  homeAboutUS: require("@assets/images/homeAboutUS.png"),
  homeAboutUsMobile: require("@assets/images/homeAboutUsMobile.png"),
  roofTopSystem: require("@assets/images/roofTopSystem.png"),
  waterPump: require("@assets/images/waterPump.png"),
  heaterSystem: require("@assets/images/heaterSystem.png"),
  heaterSystem2: require("@assets/images/heaterSystem2.png"),
  recentProject1: require("@assets/images/recentProject1.png"),
  recentProject2: require("@assets/images/recentProject2.png"),
  recentProject3: require("@assets/images/recentProject3.png"),
  recentProject4: require("@assets/images/recentProject4.png"),
  ourpartnerBg: require("@assets/images/ourpartnerBg.png"),
  LogoDGVCL: require("@assets/images/LogoDGVCL.png"),
  LogoGUVNL: require("@assets/images/LogoGUVNL.png"),
  LogoUGVCL: require("@assets/images/LogoUGVCL.png"),
  LogoMGVCL: require("@assets/images/LogoMGVCL.png"),
  LogoPGVCL: require("@assets/images/LogoPGVCL.png"),
  GEDA: require("@assets/images/GEDA.png"),
  MNRE: require("@assets/images/MNRE.png"),
  partnerBgMobile: require("@assets/images/partnerBgMobile.png"),
  BannerTitle: require("@assets/images/BannerTitle.png"),
  project2: require("@assets/images/project2.png"),
  project3: require("@assets/images/project3.png"),
  project4: require("@assets/images/project4.png"),
  project5: require("@assets/images/project5.png"),
  project6: require("@assets/images/project6.png"),
  project7: require("@assets/images/project7.png"),
  project8: require("@assets/images/project8.png"),
  project9: require("@assets/images/project9.png"),
  project10: require("@assets/images/project10.png"),
  project11: require("@assets/images/project11.png"),
  project12: require("@assets/images/project12.png"),
  project13: require("@assets/images/project13.png"),
  project14: require("@assets/images/project14.png"),
  contactUs1: require("@assets/images/contactUs1.png"),
  contactUs2: require("@assets/images/contactUs2.png"),
  requestQuote: require("@assets/images/requestQuote.png"),
};

const videoData = {
  aboutUs: require("@assets/videos/aboutUs.mp4"),
};

export { imageData, videoData };
