import { imageData } from "@constant/images/Images";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import CommonButton from "@componets/button/CommonButton";
import { useNavigate } from "react-router-dom";

const HomeBanner = () => {
  const navigate = useNavigate();
  const { innerWidth } = window;

  const widthSet = () => {
    if (innerWidth >= 1440) {
      return 180;
    } else if (innerWidth >= 1024) {
      return 150;
    } else if (innerWidth >= 768) {
      return 150;
    } else if (innerWidth >= 425) {
      return 150;
    } else if (innerWidth >= 375) {
      return 150;
    } else if (innerWidth >= 320) {
      return 150;
    } else {
      return 180;
    }
  };

  return (
    <div className="flex  flex-col">
      {/* backgroundImage */}
      <div className="sm:flex hidden 2xl:h-[811px] xl:h-[630px] md:h-auto w-full relative ">
        <img
          src={imageData.banner}
          loading="lazy"
          alt=""
          className="w-full h-full"
        />
      </div>
      <div className="flex sm:hidden h-[484px] w-full relative">
        <img
          src={imageData.BannerHome}
          loading="lazy"
          alt=""
          className="h-full w-full"
        />
      </div>
      {/* title content */}
      <div className="flex absolute flex-col xl:justify-center md:justify-start xl:pt-0 lg:pt-11 sm:pt-5 pt-10 h-full 2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5">
        {/* <span
          className="animate-fadeInDown xl:text-7xl lg:text-5xl md:text-3xl sm:text-3xl text-3xl font-medium sm:text-left text-center"
          style={{
            color: ConstantFontColor.white,
            fontFamily: constantFontFamily.rubik,
          }}
        >
          Join the solar
          <br /> energy revolution
        </span> */}
        <div
          className="font-medium text-center sm:text-left 2xl:text-7xl xl:text-6xl lg:text-5xl md:text-3xl sm:text-3xl text-3xl"
          style={{
            color: ConstantFontColor.white,
            fontFamily: constantFontFamily.rubik,
          }}
        >
          <div className="overflow-hidden border-r-2 border-transparent whitespace-nowrap  animate-typewriter">
            Join the solar
          </div>
          <div className="overflow-hidden border-r-2 border-transparent whitespace-nowrap  animate-typewriter2">
            energy revolution
          </div>
        </div>

        <span
          className="animate-flipInX transition-all duration-500 2xl:text-lg xl:text-base md:text-base text-sm font-normal sm:text-left text-center lg:pt-10 md:pt-6 pt-4"
          style={{
            color: ConstantFontColor.white,
            fontFamily: constantFontFamily.rubik,
          }}
        >
          We're transforming access to renewable energy with simple, powerful
          solar
          <br /> solutions.
        </span>

        <div className="xl:mt-14 lg:mt-10 sm:pt-5 pt-5 flex sm:flex-row flex-col sm:space-x-4 space-y-4 sm:space-y-0 sm:items-start items-center">
          <CommonButton
            title="About Us"
            fontWeight="500"
            backgroundColor={ConstantFontColor.buttonColor}
            arrowIcon={true}
            radius={3}
            width={widthSet()}
            onClick={() => {
              navigate("/aboutUs");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
          />
          <CommonButton
            title="Our Services"
            fontWeight="500"
            backgroundColor={ConstantFontColor.commanColor}
            arrowIcon={true}
            radius={3}
            width={widthSet()}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
