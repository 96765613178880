import React from "react";
import { IoClose } from "react-icons/io5";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { useNavigate } from "react-router-dom";

interface SideBarProps {
  headerTab: { title: string }[];
  handleCloseSideBar: () => void;
  selectedTabItem: string;
  headerTabClick: (index: number) => void;
}

const SideBar: React.FC<SideBarProps> = ({
  handleCloseSideBar,
  headerTab,
  selectedTabItem,
  headerTabClick,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex justify-end items-end">
      <div className="bg-white w-full h-full p-5 flex flex-col">
        <div className="flex w-full justify-end pb-4">
          <IoClose
            className="text-3xl cursor-pointer"
            style={{ color: ConstantFontColor.commanColor }}
            onClick={() => {
              handleCloseSideBar();
            }}
          />
        </div>

        {headerTab.map((headerTitle, index) => {
          return (
            <div
              className="pt-2"
              onClick={() => {
                headerTabClick(index);
              }}
            >
              <span
                className={` text-base cursor-pointer transition-all duration-300 ease-in-out `}
                style={{
                  color:
                    selectedTabItem === headerTitle.title
                      ? ConstantFontColor.buttonColor
                      : ConstantFontColor.commanColor,
                  fontFamily: constantFontFamily.rubik,
                  fontWeight: selectedTabItem === headerTitle.title ? 500 : 400,
                }}
              >
                {headerTitle?.title}{" "}
              </span>
            </div>
          );
        })}
        <button
          className="text-sm rounded-lg p-3 mt-6 cursor-pointer"
          style={{
            fontFamily: constantFontFamily.rubik,
            backgroundColor: ConstantFontColor.buttonColor,
            color: ConstantFontColor.white,
          }}
          onClick={() => {
            navigate("/requestQuote");
          }}
        >
          REQUEST A QUOTE
        </button>
      </div>
    </div>
  );
};

export default SideBar;
