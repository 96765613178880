import React from "react";
import { imageData } from "@constant/images/Images";
import Tittle from "@common/tittle/Tittle";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import CommonButton from "@componets/button/CommonButton";
import { useNavigate } from "react-router-dom";

const generateLoremIpsum = (length: number) => {
  return "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ".repeat(
    length
  );
};

const HomeAboutsUs = () => {
  const navigate = useNavigate();
  return (
    <div className="2xl:px-44 xl:px-28 lg:px-12 md:px-14 sm:px-10 px-5 flex xl:flex-row sm:flex-col flex-col sm:py-14 py-1   xl:justify-between sm:justify-center">
      <div className="xl:w-2/5 sm:w-full w-full">
        <Tittle
          colorTitle="ABOUT US"
          title="We Introduce Ourself as Fast Growing & Innovative Company."
          textAlign="left"
        />
        <p
          className="text-left py-5 2xl:text-base sm:text-sm text-sm font-light"
          style={{
            color: ConstantFontColor.greyText,
            fontFamily: constantFontFamily.rubik,
          }}
        >
          {generateLoremIpsum(5)}
        </p>
        <p
          className="text-left 2xl:text-base sm:text-sm text-sm font-light pb-10"
          style={{
            color: ConstantFontColor.greyText,
            fontFamily: constantFontFamily.rubik,
          }}
        >
          {generateLoremIpsum(5)}{" "}
        </p>
        <CommonButton
          arrowIcon
          title="Read More"
          radius={3}
          backgroundColor={ConstantFontColor.buttonColor}
          fontWeight="500"
          onClick={() => {
            navigate("/aboutUs");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        />
      </div>
      <div className="xl:w-2/4 lg:w-9/12 sm:w-full  sm:flex hidden">
        <img
          src={imageData.homeAboutUS}
          loading="lazy"
          className="w-full h-full"
          alt=""
        />
      </div>
      <div className="flex sm:hidden pt-10">
        <img
          src={imageData.homeAboutUsMobile}
          loading="lazy"
          alt=""
          className="h-full w-full"
        />
      </div>
    </div>
  );
};

export default HomeAboutsUs;
