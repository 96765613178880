import TittleBanner from "@componets/mainTittleBanner/TittleBanner";
import RequestQuoteFormData from "./requestQuoteForm/RequestQuoteFormData";
import TopBar from "@componets/topBar/TopBar";
import Header from "@componets/header/Header";
import Footer from "@componets/footer/Footer";

const RequestQuote = () => {
  return (
    <div>
      <TopBar />
      <Header />
      <TittleBanner mainTitle="Request A Quote" subTitle="Request A Quote" />
      <RequestQuoteFormData />
      <Footer />
    </div>
  );
};

export default RequestQuote;
