import React from "react";
import { CiLocationOn } from "react-icons/ci";
import { constantFontFamily } from "@constant/constantFontStyle/constantFontFamily";
import { ConstantFontColor } from "@constant/ConstantFontColor";
import Tittle from "@common/tittle/Tittle";
import { useNavigate } from "react-router-dom";
import CommonButton from "@componets/button/CommonButton";

interface ProjectType {
  image: string;
  name: string;
  location: string;
}
interface RecentProjectProps {
  data: ProjectType[];
  limit?: number;
  showButton?: boolean;
  mainTitle?: boolean;
  homePageCart?: string;
}

const RecentProject: React.FC<RecentProjectProps> = ({
  data,
  limit,
  showButton,
  mainTitle,
  homePageCart,
}) => {
  const projectsToShow = limit ? data.slice(0, limit) : data;

  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col justify-center items-center w-full  ${
        homePageCart
          ? "pb-5 sm:pb-10 lg:pb-10 2xl:pt-5"
          : "2xl:py-16 xl:py-14 sm:py-10 py-9"
      }`}
    >
      {mainTitle ? (
        <Tittle colorTitle="FEATURED PROJECTS" title="Our Recent Works" />
      ) : null}

      <div
        className={`flex sm:pt-10 pt-5 flex-wrap justify-center ${
          projectsToShow.length === 2 ? "flex-col" : "flex-row"
        }`}
      >
        {projectsToShow.map((projects, index) => (
          <div
            key={index}
            className="group flex flex-col sm:m-1 xl:m-3 2xl:m-5"
          >
            <div className="w-64 sm:w-96 md:w-[340px] lg:w-96 xl:w-[280px] 2xl:w-[350px] lg:h-64 xl:h-[304px]">
              <img
                src={projects.image}
                loading="lazy"
                alt=""
                className="h-full w-full"
              />
            </div>
            <span
              className="sm:text-base group-hover:animate-fadeInDown text-sm font-semibold text-left pt-3 line-clamp-1 w-60 sm:w-44 md:w-11/12 xl:w-52 2xl:w-80"
              style={{
                fontFamily: constantFontFamily.rubik,
                color: homePageCart
                  ? ConstantFontColor.white
                  : ConstantFontColor.commanColor,
              }}
            >
              {projects.name}
            </span>
            <div
              className="group-hover:animate-fadeInDown flex items-center lg:pb-0 sm:pb-4 pb-4"
              style={{
                color: homePageCart
                  ? ConstantFontColor.lightGreyText
                  : ConstantFontColor.greyText,
              }}
            >
              <CiLocationOn className="text-xl mr-1" />
              <span
                className="sm:text-[15px] text-xs text-left w-52 sm:w-44 md:w-11/12 xl:w-52 2xl:w-80 line-clamp-2"
                style={{
                  fontFamily: constantFontFamily.rubik,
                }}
              >
                {projects.location}
              </span>
            </div>
          </div>
        ))}
      </div>
      {showButton ? (
        <div className="sm:pt-8 pt-5">
          <CommonButton
            arrowIcon
            title="Explore More"
            backgroundColor={ConstantFontColor.buttonColor}
            radius={4}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate("/projects");
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RecentProject;
